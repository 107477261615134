import Hero from '../components/Landing/hero';
import ApplicationContainer from '../components/application-container';
import ArticleList from '../components/Landing/article-list';
import ContentSection from '../components/content-section';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SearchArticles from '../components/Blog/search-articles';
import { graphql } from 'gatsby';
import { SEO as Seo } from '../components/seo';
import { noSelect } from '../styles';

const BlogIndex = ({ data }) => {
  const posts = data.allContentfulBlogPost.edges;
  const [edge] = data.allContentfulPerson.edges;
  const author = edge.node;
  const [keyword, setKeyword] = useState('');
  const [tagValue, setTagValue] = useState('TAGS');
  const [searchedArticles, setSearchedArticles] = useState([]);

  const onSearchChange = (value) => {
    const trimmed = value.trim();
    setKeyword(trimmed);
    if (!trimmed) {
      setSearchedArticles([]);
      return;
    }
    let foundArticles;

    switch (tagValue) {
      case 'TITLE': {
        foundArticles = posts.filter(({ node }) => {
          return node.title.toLowerCase().includes(trimmed.toLowerCase());
        });
        break;
      }
      default: {
        foundArticles = posts.filter(({ node }) => {
          return node.tags.find((tag) =>
            tag.toLowerCase().includes(trimmed.toLowerCase()),
          );
        });
        break;
      }
    }
    setSearchedArticles(foundArticles.length ? foundArticles : []);
  };

  return (
    <>
      <ApplicationContainer>
        <Hero data={author} />
      </ApplicationContainer>

      <ApplicationContainer>
        <ContentSection minHeight={800}>
          <SearchArticles
            tagValue={tagValue}
            setTagValue={setTagValue}
            onChange={(value) => {
              onSearchChange(value ?? '');
            }}
          />
          <>
            <React.Fragment>
              <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                <Typography align="left" variant="h6" sx={noSelect}>
                  {keyword
                    ? `Search results for "${keyword.trim()}"`
                    : 'Latest Articles'}
                </Typography>
                <Divider light />
              </Grid>
              <ArticleList
                posts={keyword ? searchedArticles : posts}
                justPreviews={true}
              />
            </React.Fragment>
          </>
        </ContentSection>
      </ApplicationContainer>
    </>
  );
};

export default BlogIndex;

export const Head = () => {
  const siteTitle = 'Frainbreeze | Blog';
  return <Seo title={siteTitle} />;
};

export const pageQuery = graphql`
  query BlogQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          description {
            childMarkdownRemark {
              html
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            width
            height
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
