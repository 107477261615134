import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material';

const styles = (palette) => ({
  contained: {
    backgroundColor: palette.background.paper,
    color: palette.warning.light,
    fontFamily: 'JetBrains Mono !important',
  },
  outlined: {
    color: palette.primary.light,
    fontFamily: 'JetBrains Mono !important',
  },
});
const SearchArticles = ({ onChange, tagValue, setTagValue }) => {
  const theme = useTheme();
  const isSelected = (name) =>
    tagValue === name
      ? styles(theme.palette).contained
      : styles(theme.palette).outlined;

  const onClickChange = (name) => {
    setTagValue(name);
  };

  return (
    <Grid
      container
      item
      xs={12}
      mt={'2rem'}
      mb={'2rem'}
      justifyContent="center"
    >
      <TextField
        id="outlined-basic"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        sx={(theme) => ({ backgroundColor: theme.palette.background.light })}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <ButtonGroup
        sx={{ marginLeft: '1rem' }}
        color="primary"
        aria-label="outlined primary button group"
      >
        <Button sx={isSelected('TAGS')} onClick={() => onClickChange('TAGS')}>
          Tags
        </Button>
        <Button sx={isSelected('TITLE')} onClick={() => onClickChange('TITLE')}>
          Title
        </Button>
      </ButtonGroup>
    </Grid>
  );
};

export default SearchArticles;
